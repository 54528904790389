<template>
  <section class="new-goods m-scroll">
      <ul class="m-new-wrap">
        <li class="m-new-item" v-for="(item, index) in goodsList" :key="index">
          <router-link :to="`/zh/goods/detail/${item.id}`" class="goods">
            <img :src="item.image_url" alt="goods">
            <div class="eleven" v-if="item.merchant_id == 1 && elevenShow"></div>
            <p class="van-multi-ellipsis--l2">
              <span class="m-mooby" v-if="item.merchant_id == 1">自营</span>
              <span>{{item.goods_info.name}}</span>
            </p>
            <div class="goods-price">
                <div>
                  <p><span>$</span><span>{{(item.activity_min_price *1).toFixed(0) != 0 ? item.activity_min_price : (item.min_price * (item.off == 0 ? 1 : item.off / 100)).toFixed(2)}}</span></p>
                  <p v-if="(item.activity_min_price *1).toFixed(0) != 0 ? true : item.off = 0">${{item.min_price}}</p>
                </div>
                <img  @click.prevent="handleCart(item.id, $event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
            </div>
          </router-link>
        </li>
        <li class="m-new-item"></li>
      </ul>
  </section>
</template>

<script>

export default {
  name:'IndexNew',
  props:['goodsList'],
  data() {
    return {
      elevenShow: false // 圣诞活动边框显示
    }
  },
  methods: {
    handleCart(goods,e){
        console.log(goods)
        this.$emit('cartClick',goods,e)
    }
  },
}
</script>

<style lang="less" scoped>
.new-goods {
  padding-left: 16px;
  margin-bottom: 5px;
  overflow-x: auto;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: hidden;
  transition: all 0.5s ease;
  background-color: #fff;
  .m-new-wrap {
    display: flex;
    list-style: none;
    width: auto;
    .m-new-item{
      margin-right: 12px;
      display: block;
      width: 108px;
      
    }
    .m-new-item:last-child{
      min-width: 4px;
    }
    .goods {
      display: inline-block;
      width: 108px;
      color: #333;
      position: relative;
      &>img {
        width: 108px;
        height: 108px;
        border-radius: 5px;
      }
      &>.eleven {
        position: absolute;
        top: 0;
        left: 0;
        width: 108px;
        height: 108px;
        background: url('../../assets/index/eleven.png');
        background-size: 100% 100%;
        border-radius: 5px;
      }
      &>p {
        margin-top: 8px;
        font-size: 12px;
        letter-spacing: 0.31px;
        line-height: 18px;
        &>span:nth-of-type(2){
          font-size: 12px;
          line-height: 20px;
        }
      }
      .goods-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 8px 0  3px 0;
        font-size: 12px;
        &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &>p:nth-of-type(1){
            color: #ED2A24;
            &>span:nth-of-type(2){
                font-size: 16px;
            }
            }
            &>p:nth-of-type(2){
            margin-top: 2px;
            color: #999999;
            text-decoration:line-through ;
            }
        }
        &>img {
            width: 40px;
            height: 40px;
        }
     }
    }
  }
  
}

</style>