<template>
  <section class="m-new-product m-scroll" ref="scroll"  @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header" style="background:#C7B0FB;">
      <img  @click="handleBack" src="@/assets/new-product/icon-fanhui@2x.png" alt="back">
      <span>
        <img src="@/assets/new-product/new.png" alt="" class="top-img">
      </span>
      <router-link to="/zh/cart" >
        <img src="@/assets/new-product/cart.png" alt="img">
        <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
      </router-link>
    </div>
    
    <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};`"></div>

    <div class="my-swipe">
      <img :src="swipeImgs[0].pic_url" alt="" class="swipe-img" @click="$router.push(swipeImgs[0].link)">
    </div>

    <div class="slide-product" v-for="item in newSaleData" :key="item.id">
      <div class="slide-wrapper">
        <img src="@/assets/new-product/bg01@2x.png" alt="" class="slide-title">
        <p>{{item.title}}</p>
        <router-link :to="`/zh/subnew?type=${item.id}&title=${item.title}`" class="slide-all">
          <span>全部</span>
          <img src="@/assets/new-product/icon_genduo@2x.png" alt="">
        </router-link>
        <new-goods @cartClick="handleCart" :goodsList="item.goods_list"></new-goods>
      </div>
    </div>

    <!-- 滚动切换 -->
    <van-tabs title-active-color="#ED2A24" title-inactive-color="#000000" v-model="activeSectionId"
    style="position:sticky;top:0px;z-index:99;margin-bottom:16px">
      <van-tab :title="item.title" v-for="(item, index) in classifyList" :key="index" :name="item.id">
        <!-- <index-cell :title="item.title" value="更多"  to="/" class="title"></index-cell> -->
      </van-tab>
    </van-tabs>
    <goods-columns v-if="classifyList[activeIndex]" @cartClick="handleCart" :data="goodsList" :columns="classifyList[activeIndex].row_pro_num"></goods-columns>
    <p class="search-no" v-if='noMore'>暂无更多商品</p> 
    
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MDot         from '@/components/zh/m-dot.vue'
import MSku         from '@/components/zh/m-sku.vue'
import NewGoods     from '@/components/zh/new-goods.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'

import { getNewSaleClassify, getNewSaleClassifyGoods, getNewSaleBanner, getNewSaleTitle } from '@/api/zh/newsale.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{   MDot, NewGoods, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop:0,
      tab:0,          // tabs选中的面板
      recomnd:4,
      loading:false,
      skuModal:false,
      tabTop:44,
      dotAnimate:false,
      start:false,
      start_dom:null,
      cartNum:0,
      scrollTop:false,
      swipeImgs:[{}],
      swipeBg1: '//img.alicdn.com/imgextra/i3/730320029/O1CN01EyMkhZ1C5K5sFdlpj_!!0-saturn_solar.jpg_220x220.jpg_.webp',
      swipeBg2: '//img.alicdn.com/imgextra/i3/1253260119/O1CN01XiAXfW1CkXk4EQ2qe_!!0-saturn_solar.jpg_220x220.jpg_.webp',
      goodsList: [],
      page: 1,
      islock: false,
      noMore: false,
      activeSectionId: 0,
      classifyList: [],
      activeIndex: 0,
      goodsId: '',
      newSaleData: []
    }
  },

  mounted(){
    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth

    this.getNewSaleClassifyHandle()
    this.getNewSaleBannerHandle()
    this.getNewSaleTitleHandle()
  },

  methods:{
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop

      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getNewSaleClassifyGoodsHandle()
        }
        this.islock = true
      }
    },
    handleBack(){
      this.$router.go(-1)
    },

    // 轮播图切换时间
    handleChange(index){
        let len = this.swipeImgs.length

        if(this.swipeImgs[index-1]){
            this.swipeBg1 = this.swipeImgs[index-1]
        }else{
            this.swipeBg1 = this.swipeImgs[len-1]
        }
        if(this.swipeImgs[index+1]){
            this.swipeBg2 = this.swipeImgs[index+1]
        }else{
            this.swipeBg2 = this.swipeImgs[0]
        }

    },

    handleClick(e){
        console.log(e)
    },

    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 点击筛选 tab
    handleFilter(){
      this.filter = true
    },
    // 点击月份筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getNewSaleBannerHandle() {
      // 获取新品Banner
      getNewSaleBanner(12).then(res => {
        if(res) {
          this.swipeImgs = res.data
        }
      })
    },
    getNewSaleClassifyHandle() {
      // 获取新品分类
      getNewSaleClassify().then(res => {
        if(res) {
          if (this.$store.state.isMiniProgram) {
              this.classifyList = res.data.filter(item => {
                  return  item.id != 34 && item.id != 35 && item.id != 36 && item.id != 37 && item.id != 38 && item.id != 45 && item.id != 46 && item.id != 49 && item.id != 50 && item.id != 53 && item.id != 55
              });
          }else {
           this.classifyList = res.data
          }
          if(res.data.length > 0) {
            this.activeSectionId = res.data[0].id
          }
        }
      })
    },
    getNewSaleClassifyGoodsHandle() {
      // 获取新品分类商品
      this.islock = true
      this.loading = true
      getNewSaleClassifyGoods(this.activeSectionId,{page: this.page,column_id:2}).then(res => {
        this.loading  = false
        this.islock   = false
        if(res) {
          this.goodsList = this.goodsList.concat(res.data.data)
        }else{
          this.noMore = true
        }
      })
    },
    getNewSaleTitleHandle() {
      // 获取新品标题
      getNewSaleTitle().then(res => {
        if(res) {
          this.newSaleData = res.data
        }
      })
    }
  },
  watch: {
    activeSectionId(val) {
      if(val) {
        this.classifyList.forEach((el, index) => {
          if(el.id == val) {
            this.activeIndex = index
          }
        })
        this.goodsList = []
        this.page = 1
        this.islock   = false
        this.noMore = false
        this.getNewSaleClassifyGoodsHandle()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search-no {
  line-height:80px;
  text-align:center;
  color:#888;
  font-size:14px;
}
@import './new.less';
</style>